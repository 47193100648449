<template>
  <div>
    <div class="flex-row ">
      <div class="bg-aquila-yellow  flex ">
        <div>
          <div class="mt-2 py-2 px-3">
            <div class="text-aquila-blueDark  h-10 w-10  content-center ">
              <div v-if="machineType === 'dumptruck'" class="mt-1">
                <dumpTruck></dumpTruck>
              </div>
              <div v-if="machineType === 'dozer'" class="mt-1">
                <dozer></dozer>
              </div>
              <div v-if="machineType === 'excavator'">
                <excavator></excavator>
              </div>
              <div v-if="machineType === 'plant'" class="mt-1">
                <plant></plant>
              </div>
            </div>
          </div>
        </div>

        <span class="mt-1 text-base font-aquilaHeader mr-8">{{
          data.name
        }}</span>
      </div>
      <div class="bg-white min-w-min ">
        <ResourceStatus :data="data" />
      </div>
    </div>
  </div>
</template>

<style>
/* purgecss start ignore */

.mapboxgl-popup-content {
  overflow: hidden !important;
}

.mapboxgl-popup-close-button:focus {
  background: none;
  outline: none;
  border: none;
}

.mapboxgl-popup-close-button {
  color: black !important;
  align-self: right;
  width: 36px;
  height: 36px;
  font-size: 16pt;
  border-radius: 0px;
}

/* purgecss end ignore */
</style>

<script>
import ResourceStatus from "@/components/resources/ResourceStatus";
import dozer from "@/icons/dozer.vue";
import dumpTruck from "@/icons/dumpTruck.vue";
import excavator from "@/icons/excavator.vue";
import plant from "@/icons/plant.vue";
export default {
  data() {
    return {
      active: false,
    };
  },
  props: ["data", "machineType"],
  components: {
    ResourceStatus,
    dozer,
    dumpTruck,
    excavator,
    plant,
  },
  computed: {},
  methods: {},
};
</script>
