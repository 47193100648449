<template>
  <div class="flex flex-grow screenHeightCalc overflow-hidden">
    <div class="flex" ref="filterPanel" @submit.prevent="validateFilters()">
      <div class="bg-gray-100 p-4 text-black w-80 shadow-inner">
        <div class="divide-solid divide-opacity-30 divide-y divide-gray-500">
          <div
            v-if="autoLive"
            class="pb-4 pt-2 px-2 border border-gray-300 rounded bg-gray-200"
          >
            <div class="font-aquilaHeader text-lg px-3 text-center">
              Show data from the last
            </div>
            <div class="flex  ">
              <button
                class="flex-auto my-1 rounded-l hover:bg-aquila-yellowLight  p-1"
                :class="
                  currentInterval === 1
                    ? 'bg-aquila-yellowLight'
                    : 'bg-aquila-yellowDark'
                "
                @click="updateMinutesAgo(1)"
              >
                <div class="text-xs p-0 pb-1 m-0 leading-none">
                  <span class="text-base">5</span> mins
                </div>
              </button>
              <button
                class="flex-auto my-1 rounded-none hover:bg-aquila-yellowLight  p-1"
                :class="
                  currentInterval === 2
                    ? 'bg-aquila-yellowLight'
                    : 'bg-aquila-yellowDark'
                "
                @click="updateMinutesAgo(2)"
              >
                <div class="text-xs p-0 pb-1 m-0 leading-none">
                  <span class="text-base">15</span> mins
                </div>
              </button>
              <button
                class="flex-auto my-1 rounded-none hover:bg-aquila-yellowLight  p-1"
                :class="
                  currentInterval === 3
                    ? 'bg-aquila-yellowLight'
                    : 'bg-aquila-yellowDark'
                "
                @click="updateMinutesAgo(3)"
              >
                <div class="text-xs p-0 pb-1 m-0 leading-none">
                  <span class="text-base">30</span> mins
                </div>
              </button>
              <button
                class="flex-auto my-1 rounded-none hover:bg-aquila-yellowLight  p-1"
                :class="
                  currentInterval === 4
                    ? 'bg-aquila-yellowLight'
                    : 'bg-aquila-yellowDark'
                "
                @click="updateMinutesAgo(4)"
              >
                <div class="text-xs p-0 pb-1 m-0 leading-none">
                  <span class="text-base">1</span> hour
                </div>
              </button>
              <button
                class="flex-auto my-1 rounded-none hover:bg-aquila-yellowLight  p-1"
                :class="
                  currentInterval === 5
                    ? 'bg-aquila-yellowLight'
                    : 'bg-aquila-yellowDark'
                "
                @click="updateMinutesAgo(5)"
              >
                <div class="text-xs p-0 pb-1 m-0 leading-none">
                  <span class="text-base">12</span> hours
                </div>
              </button>
              <button
                class="flex-auto my-1 rounded-r hover:bg-aquila-yellowLight p-1"
                :class="
                  currentInterval === 6
                    ? 'bg-aquila-yellowLight'
                    : 'bg-aquila-yellowDark'
                "
                @click="updateMinutesAgo(6)"
              >
                <div class="text-xs p-0 pb-1 m-0 leading-none">
                  <span class="text-base">24</span> hours
                </div>
              </button>
            </div>
          </div>

          <div class="mt-4">
            <div class="flex mt-2 mb-4  ">
              <button
                class="flex my-1 rounded-l hover:bg-aquila-yellowLight  p-1"
                :class="
                  autoLive === true
                    ? 'bg-aquila-yellowLight'
                    : 'bg-aquila-yellowDark'
                "
                @click="autoRefreshData"
              >
                <div class="ml-1 mr-1 mt-0.5 h-5 w-5">
                  <transition name="fade">
                    <div class=" flex" v-if="liveLoading">
                      <svg
                        class="animate-spin h-5 w-5 text-black"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          class="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          stroke-width="4"
                        ></circle>
                        <path
                          class="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    </div>
                  </transition>
                </div>
                <div class=" flex p-1 m-0 leading-none">
                  View site live
                </div>
                <div class="ml-1 mr-1 mt-0.5 h-5 w-5"></div>
              </button>
              <button
                class="flex-auto my-1 rounded-r hover:bg-aquila-yellowLight  p-1"
                :class="
                  autoLive === false
                    ? 'bg-aquila-yellowLight'
                    : 'bg-aquila-yellowDark'
                "
                @click="clearLiveInterval"
              >
                <div class="  p-1 m-0 leading-none">
                  Analysis
                </div>
              </button>
            </div>

            <div v-if="!autoLive">
              <div class="flex">
                <litepie-datepicker
                  v-model="dates"
                  :formatter="formatter"
                  use-range
                  class="ml-1 rounded-none"
                  ref="litepie"
                >
                </litepie-datepicker>
              </div>

              <t-select
                class="m-1 text-black"
                v-model="selectedDevice"
                :options="devices"
                text-attribute="name"
                value-attribute="guid"
                placeholder="Select Resource"
                required
              ></t-select>

              <t-select
                class="m-1"
                v-model="selectedStyle"
                :options="styles"
                attach
                placeholder="Display Style"
                required
              ></t-select>

              <div class="flex">
                <div class="flex">
                  <t-button
                    class="m-1 bg-aquila-yellow hover:bg-aquila-yellowLight"
                    @click="allLiveData"
                    >Reset filters</t-button
                  >
                </div>
                <div class="flex flex-grow">
                  <t-button
                    class="m-1 flex-grow bg-aquila-blueDark text-white hover:bg-aquila-blueLight "
                    type="submit"
                    @click="filterData"
                    >Filter</t-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <vue-mapbox-map
      class=" flex-grow "
      ref="mapbox"
      id="map-container"
      :access-token="accessToken"
      :interactive="true"
      :mapStyle="mapStyle"
      :lng="longitude"
      :lat="latitude"
      :zoom="zoom"
      :pitch="pitch"
      :bearing="bearing"
      @mapbox-ready="setMap"
    >
    </vue-mapbox-map>
    <div v-if="!autoLive">
      <vue-mapbox-feature
        v-if="map && !loading"
        :map="map"
        :uid="'geoJsonData'"
        :layer-type="
          selectedStyle === 'LineString'
            ? 'line'
            : selectedStyle === 'MultiPoint'
            ? 'circle'
            : 'heatmap'
        "
        :paint="
          selectedStyle === 'LineString'
            ? linePaint
            : selectedStyle === 'MultiPoint'
            ? circlePaint
            : heatmapPaint
        "
        :feature="geoJson.properties != null ? geoJson : null"
      ></vue-mapbox-feature>
    </div>

    <div v-if="map">
      <div v-for="(feature, index) in geoJson.features" :key="index">
        <div>
          <MapMarker
            :map="map"
            :coordinates="[
              feature.geometry.coordinates[0],
              feature.geometry.coordinates[1],
            ]"
          >
            <template slot="marker">
              <!-- Empty div to fix offseting errors  -->
              <div>
                <div
                  class="text-aquila-blueDark rounded-3xl h-10 w-10 border-aquila-blueDark border-2 content-center "
                  :class="'bg-' + colourStatus(feature.properties)"
                >
                  <div class="p-1 ">
                    <div
                      v-if="feature.properties.type.category === 'dumptruck'"
                      class="mt-1"
                    >
                      <dumpTruck></dumpTruck>
                    </div>
                    <div
                      v-if="feature.properties.type.category === 'dozer'"
                      class="mt-1"
                    >
                      <dozer></dozer>
                    </div>
                    <div
                      v-if="feature.properties.type.category === 'excavator'"
                    >
                      <excavator></excavator>
                    </div>
                    <div
                      v-if="feature.properties.type.category === 'plant'"
                      class="mt-1"
                    >
                      <plant></plant>
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <template slot="popup">
              <DetailedPopup
                :data="feature.properties"
                :machineType="feature.properties.type.category"
              />
            </template>
          </MapMarker>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
/* purgecss start ignore */
.fade-enter-active {
  transition: opacity 0.5s;
}
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

/* purgecss end ignore */
</style>
<style>
/* purgecss start ignore */
.screenHeightCalc {
  /* height of screen minus the header section */
  height: calc(100vh - 8rem);
}

/* purgecss end ignore */
</style>
<style lang="scss">
/* purgecss start ignore */

@keyframes pulse-ring {
  0% {
    transform: scale(0.33);
  }
  80%,
  100% {
    opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}

.mapboxgl-popup-content {
  padding: 0;
  margin: 0;
  min-width: 300px;
}

.mapboxgl-ctrl {
  font-family: "Montserrat", sans-serif;
  padding: 12px;
  display: flex;
  align-items: center;
}

.mapboxgl-ctrl input:focus {
  outline: none;
}

.mapboxgl-ctrl input {
  width: 100%;
}

/* purgecss end ignore */
</style>

<script>
import Mapbox from "mapbox-gl";
import MapboxGeocoder from "mapbox-gl-geocoder";
import VueMapboxMap from "vue-mapbox-map";
import VueMapboxFeature from "vue-mapbox-feature";
import MapMarker from "@/components/map/MapboxComponents/Marker";
import DetailedPopup from "@/components/map/DetailedPopup.vue";
import moment from "moment";
import dozer from "@/icons/dozer.vue";
import dumpTruck from "@/icons/dumpTruck.vue";
import excavator from "@/icons/excavator.vue";
import plant from "@/icons/plant.vue";

import { mapState, mapActions } from "vuex";
import LitepieDatepicker from "vue2-litepie-datepicker";

import axios from "axios";

export default {
  name: "SiteViewMap",
  components: {
    "vue-mapbox-map": VueMapboxMap,
    "vue-mapbox-feature": VueMapboxFeature,
    DetailedPopup,
    MapMarker,
    LitepieDatepicker,
    dozer,
    dumpTruck,
    excavator,
    plant,
  },
  data() {
    return {
      loading: true,
      liveLoading: true,
      currentInterval: 6,
      minuteInterval: [
        { step: 1, interval: 5 },
        { step: 2, interval: 15 },
        { step: 3, interval: 30 },
        { step: 4, interval: 60 },
        { step: 5, interval: 720 },
        { step: 6, interval: 1440 },
      ],
      autoLive: true,
      autoLiveIntervalId: [],
      timeout: 27000,
      refreshInterval: 3000,
      map: null, //nottodo: Needs to move onto store, can't use vue-mapbox-map because it breaks the state behind the scenes
      /* purgecss start ignore */
      circlePaint: {
        "circle-radius": 5,
        "circle-color": "#29434e",
        "circle-opacity": 0.5,
        "circle-stroke-width": 2,
        "circle-stroke-color": "#29434e",
        "circle-stroke-opacity": 0.8,
      },
      linePaint: {
        "line-color": "#1e88e5",
        "line-width": 5,
      },
      heatmapPaint: {
        "heatmap-radius": 50,
        "heatmap-weight": 1,
        "heatmap-intensity": 1,
        "heatmap-color": [
          "interpolate",
          ["linear"],
          ["heatmap-density"],
          0,
          "rgba(0, 0, 0, 0)",
          0.1,
          "rgba(0, 0, 255, 0.5)",
          0.3,
          "rgba(0, 255, 255, 0.5)",
          0.5,
          "rgba(0, 255, 0, 0.5)",
          0.7,
          "rgba(255, 255, 0, 0.5)",
          1,
          "rgba(255, 0, 0, 0.5)",
        ],
      },
      /* purgecss end ignore */
      dates: [],
      formatter: {
        date: "YYYY-MM-DD",
        month: "MM",
      },
      devices: [],
      selectedDevice: "",

      selectedStyle: "",
      styles: ["LineString", "MultiPoint", "Heatmap"],
    };
  },

  computed: {
    ...mapState({
      accessToken: (state) => state.map.accessToken,
      mapStyle: (state) => state.map.mapStyle,
      zoom: (state) => state.map.zoom,
      longitude: (state) => state.map.longitude,
      latitude: (state) => state.map.latitude,
      pitch: (state) => state.map.pitch,
      bearing: (state) => state.map.bearing,
      geoJson: (state) => state.map.geoJson,
    }),
  },

  methods: {
    ...mapActions({
      fetchGeoJson: "map/fetchGeoJson",
      fetchLatestGeoJsonAllResources: "map/fetchLatestGeoJsonAllResources",
    }),

    colourStatus(data) {
      const tenMinutesAgo = moment().subtract(10, "minutes");

      if (moment(data.collected).isAfter(tenMinutesAgo)) return "aquila-yellow";
      else return "gray-500";
    },

    buildMapObject() {
      let initialObj = {
        minutes: this.minuteInterval[this.currentInterval - 1].interval,
        longitude: this.longitude,
        latitude: this.latitude,
      };

      if (this.map?.getZoom()) initialObj.zoom = this.map.getZoom();

      if (this.map?.getPitch()) initialObj.pitch = this.map.getPitch();
      return initialObj;
    },

    async autoRefreshData() {
      this.liveLoading = true;
      this.autoLive = true;

      let mapBuilder = this.buildMapObject();

      await this.fetchLatestGeoJsonAllResources(mapBuilder);
      this.autoLiveIntervalId.push(
        await setInterval(() => {
          this.updatePositions();
        }, this.refreshInterval)
      );
      this.liveLoading = false;
    },

    async clearLiveInterval() {
      this.autoLive = false;
      for (let i = 0; i < this.autoLiveIntervalId.length; i++) {
        clearInterval(this.autoLiveIntervalId[i]);
      }
      this.autoLiveIntervalId = [];
      console.log("Clear live interval success!");
    },

    async filterData() {
      this.loading = true;

      let style = this.selectedStyle;

      if (style === "Heatmap") style = "MultiPoint";

      await this.fetchGeoJson({
        selectedDevices: this.selectedDevice,
        dateRange: this.dates,
        aggregate: style,
        zoom: 16,
      }).then(() => {
        this.loading = false;
      });
    },

    async updateMinutesAgo(step) {
      this.currentInterval = step;

      if (this.autoLiveIntervalId.count() > 0) await this.clearLiveInterval();

      await this.autoRefreshData();
    },

    async allLiveData() {
      this.dates = [];
      this.selectedDevice = [];
      this.selectedStyle = "";
      this.liveLoading = true;

      this.$refs.litepie.clearPicker();

      await this.fetchLatestGeoJsonAllResources({
        minutes: this.minuteInterval[this.currentInterval - 1].interval,
      }).then(() => {
        this.liveLoading = false;
      });
    },

    setMap(map) {
      this.addBuildingGeometry(map);
      this.map = map;
    },

    async updatePositions() {
      clearTimeout();
      this.liveLoading = true;
      console.log("auto live is running!");
      await this.fetchLatestGeoJsonAllResources({
        minutes: this.minuteInterval[this.currentInterval - 1].interval,
      }).then(() => {
        this.liveLoading = false;
      });

      setTimeout(() => {}, this.timeout);
    },

    async dataOutdated() {
      setTimeout(() => {}, this.timeout);
      clearTimeout();
    },

    addBuildingGeometry(map) {
      // The 'building' layer in the mapbox-streets vector source contains building-height
      // data from OpenStreetMap.

      // Insert the layer beneath any symbol layer.
      const layers = map.getStyle().layers;

      let labelLayerId;
      for (let i = 0; i < layers.length; i++) {
        if (layers[i].type === "symbol" && layers[i].layout["text-field"]) {
          labelLayerId = layers[i].id;
          break;
        }
      }

      map.addLayer(
        {
          id: "3d-buildings",
          source: "composite",
          "source-layer": "building",
          filter: ["==", "extrude", "true"],
          type: "fill-extrusion",
          minzoom: 5,
          paint: {
            "fill-extrusion-color": "#aaa",

            // use an 'interpolate' expression to add a smooth transition effect to the
            // buildings as the user zooms in
            "fill-extrusion-height": [
              "interpolate",
              ["linear"],
              ["zoom"],
              15,
              0,
              15.05,
              ["get", "height"],
            ],
            "fill-extrusion-base": [
              "interpolate",
              ["linear"],
              ["zoom"],
              15,
              0,
              15.05,
              ["get", "min_height"],
            ],
            "fill-extrusion-opacity": 0.6,
          },
        },
        labelLayerId
      );

      map.addControl(
        new MapboxGeocoder({
          accessToken: this.accessToken,
          mapboxgl: Mapbox,
        })
      );
      setTimeout(() => map.resize(), 3000);
    },

    //TODO: Should probably put on the store
    async fetchDevices() {
      this.loading = true;
      await axios
        .get(
          process.env.VUE_APP_API_BASE_URL + `api/resources?with_device=${true}`
        )
        .then((response) => {
          this.loading = false;
          this.devices = response.data;
          // console.error(response.data);
          if (response.data.geometry)
            throw new Error(
              "No geometry data provide dor selected device at that time"
            );
        })
        .catch((error) => {
          console.log("There was a problem...: ", error);
        });
    },
  },
  async created() {
    //Get live data on page load
    this.liveLoading = true;
    await this.fetchLatestGeoJsonAllResources({
      minutes: this.minuteInterval[this.currentInterval - 1].interval,
    }).then(() => {
      this.liveLoading = false;
      console.log("There was a problem...: ");

      this.dataOutdated();
    });

    await this.fetchDevices();
    await this.autoRefreshData();
  },

  beforeDestroy() {
    this.clearLiveInterval();
  },
};
</script>
