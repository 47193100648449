<template>
  <!-- 

This is imported into the popup component

 -->

  <div class="p-2 ">
    <div class="font-aquilaHeader text-lg p-1 h-1/3">
      <span class="mb-auto mt-auto">Last seen:</span>
      <div class="font-aquila text-xs">
        {{ data.collected | fromNow() }}
      </div>
    </div>
    <div class="px-1  text-gray-400">
      <div class="font-aquila text-xs">
        Collected at: {{ data.collected | moment("MMMM Do YYYY, h:mm:ss a") }}
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: ["data"],
  data() {
    return {};
  },
  created() {},
  filters: {
    fromNow(date) {
      return moment(date).fromNow();
    },
  },
  methods: {},
};
</script>
