<template>
  <div>
    <slot name="marker" />
    <slot name="popup" />

    <!-- slot for popup -->
    <slot v-if="marker" />
  </div>
</template>

<script>
import Mapbox from "mapbox-gl";

export default {
  name: "MapMarker",
  props: {
    // mapbox marker options
    map: {
      type: Object,
      required: true,
    },
    offset: {
      type: [Object, Array],
      default: () => [0, 0],
    },
    coordinates: {
      type: Array,
      required: true,
    },
    color: {
      type: String,
      default: "blue",
    },
    anchor: {
      type: String,
      default: "center",
    },
    draggable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      initial: true,
      marker: undefined,
      popup: null,
    };
  },
  watch: {
    coordinates(lngLat) {
      if (this.initial) return;
      this.marker.setLngLat(lngLat);
    },
  },
  mounted() {
    this.initial = false;
    this.addMarker();
  },
  beforeDestroy() {
    if (this.map !== undefined && this.marker !== undefined) {
      this.marker.remove();
    }
  },
  methods: {
    addMarker() {
      this.popup = new Mapbox.Popup({ offset: 25 }).setDOMContent(
        this.$slots.popup[0].elm
      );

      this.marker = new Mapbox.Marker(this.$slots.marker[0].elm);

      this.marker
        .setLngLat(this.coordinates)
        .setPopup(this.popup)
        .addTo(this.map);
    },
    remove() {
      this.marker.remove();
    },
    togglePopup() {
      return this.marker.togglePopup();
    },
  },
};
</script>
