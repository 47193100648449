<template>
  <div class="flex flex-grow ">
    <!-- <ForgeViewer> </ForgeViewer> -->
    <Mapbox></Mapbox>
  </div>
</template>

<style>
.timeline {
  width: auto;
  margin: 25px 0;
  list-style-type: none;
  display: flex;
  padding: 0;
  text-align: center;
}

.timestamp {
  width: auto;
  margin-bottom: 20px;
  padding: 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 100;
  font-size: 14px;
}
.status {
  padding: 0px 0px;
  font-size: small;
  display: flex;
  justify-content: center;
  border-top: 4px solid rgb(5, 150, 105);
  position: relative;
}

.status span {
  font-weight: 400;
  padding-top: 12px;
}
.status span:before {
  content: "";
  width: 25px;
  height: 25px;
  background-color: white;
  border-radius: 25px;
  border: 4px solid rgb(5, 150, 105);
  position: absolute;
  top: -15px;
  left: 40%;
}

.complete span:before {
  color: white;
  text-align: center;
  vertical-align: middle;
  content: "";
  width: 25px;
  height: 25px;

  border-radius: 25px;

  position: absolute;
  top: -15px;
  left: 40%;

  background-color: rgb(5, 150, 105);
  border: 4px solid rgb(5, 150, 105);
}
</style>

<script>
// @ is an alias to /src
// import ForgeViewer from "@/forge/ForgeViewer.vue";
import Mapbox from "../components/map/Mapbox.vue";

export default {
  name: "MapboxView",
  data() {
    return {};
  },
  watch: {},
  components: {
    // ForgeViewer,
    Mapbox,
  },

  computed: {},
  async mounted() {},
  methods: {},
};
</script>
