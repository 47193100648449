var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-grow screenHeightCalc overflow-hidden"},[_c('div',{ref:"filterPanel",staticClass:"flex",on:{"submit":function($event){$event.preventDefault();return _vm.validateFilters()}}},[_c('div',{staticClass:"bg-gray-100 p-4 text-black w-80 shadow-inner"},[_c('div',{staticClass:"divide-solid divide-opacity-30 divide-y divide-gray-500"},[(_vm.autoLive)?_c('div',{staticClass:"pb-4 pt-2 px-2 border border-gray-300 rounded bg-gray-200"},[_c('div',{staticClass:"font-aquilaHeader text-lg px-3 text-center"},[_vm._v(" Show data from the last ")]),_c('div',{staticClass:"flex  "},[_c('button',{staticClass:"flex-auto my-1 rounded-l hover:bg-aquila-yellowLight  p-1",class:_vm.currentInterval === 1
                  ? 'bg-aquila-yellowLight'
                  : 'bg-aquila-yellowDark',on:{"click":function($event){return _vm.updateMinutesAgo(1)}}},[_vm._m(0)]),_c('button',{staticClass:"flex-auto my-1 rounded-none hover:bg-aquila-yellowLight  p-1",class:_vm.currentInterval === 2
                  ? 'bg-aquila-yellowLight'
                  : 'bg-aquila-yellowDark',on:{"click":function($event){return _vm.updateMinutesAgo(2)}}},[_vm._m(1)]),_c('button',{staticClass:"flex-auto my-1 rounded-none hover:bg-aquila-yellowLight  p-1",class:_vm.currentInterval === 3
                  ? 'bg-aquila-yellowLight'
                  : 'bg-aquila-yellowDark',on:{"click":function($event){return _vm.updateMinutesAgo(3)}}},[_vm._m(2)]),_c('button',{staticClass:"flex-auto my-1 rounded-none hover:bg-aquila-yellowLight  p-1",class:_vm.currentInterval === 4
                  ? 'bg-aquila-yellowLight'
                  : 'bg-aquila-yellowDark',on:{"click":function($event){return _vm.updateMinutesAgo(4)}}},[_vm._m(3)]),_c('button',{staticClass:"flex-auto my-1 rounded-none hover:bg-aquila-yellowLight  p-1",class:_vm.currentInterval === 5
                  ? 'bg-aquila-yellowLight'
                  : 'bg-aquila-yellowDark',on:{"click":function($event){return _vm.updateMinutesAgo(5)}}},[_vm._m(4)]),_c('button',{staticClass:"flex-auto my-1 rounded-r hover:bg-aquila-yellowLight p-1",class:_vm.currentInterval === 6
                  ? 'bg-aquila-yellowLight'
                  : 'bg-aquila-yellowDark',on:{"click":function($event){return _vm.updateMinutesAgo(6)}}},[_vm._m(5)])])]):_vm._e(),_c('div',{staticClass:"mt-4"},[_c('div',{staticClass:"flex mt-2 mb-4  "},[_c('button',{staticClass:"flex my-1 rounded-l hover:bg-aquila-yellowLight  p-1",class:_vm.autoLive === true
                  ? 'bg-aquila-yellowLight'
                  : 'bg-aquila-yellowDark',on:{"click":_vm.autoRefreshData}},[_c('div',{staticClass:"ml-1 mr-1 mt-0.5 h-5 w-5"},[_c('transition',{attrs:{"name":"fade"}},[(_vm.liveLoading)?_c('div',{staticClass:" flex"},[_c('svg',{staticClass:"animate-spin h-5 w-5 text-black",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24"}},[_c('circle',{staticClass:"opacity-25",attrs:{"cx":"12","cy":"12","r":"10","stroke":"currentColor","stroke-width":"4"}}),_c('path',{staticClass:"opacity-75",attrs:{"fill":"currentColor","d":"M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"}})])]):_vm._e()])],1),_c('div',{staticClass:" flex p-1 m-0 leading-none"},[_vm._v(" View site live ")]),_c('div',{staticClass:"ml-1 mr-1 mt-0.5 h-5 w-5"})]),_c('button',{staticClass:"flex-auto my-1 rounded-r hover:bg-aquila-yellowLight  p-1",class:_vm.autoLive === false
                  ? 'bg-aquila-yellowLight'
                  : 'bg-aquila-yellowDark',on:{"click":_vm.clearLiveInterval}},[_c('div',{staticClass:"  p-1 m-0 leading-none"},[_vm._v(" Analysis ")])])]),(!_vm.autoLive)?_c('div',[_c('div',{staticClass:"flex"},[_c('litepie-datepicker',{ref:"litepie",staticClass:"ml-1 rounded-none",attrs:{"formatter":_vm.formatter,"use-range":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}})],1),_c('t-select',{staticClass:"m-1 text-black",attrs:{"options":_vm.devices,"text-attribute":"name","value-attribute":"guid","placeholder":"Select Resource","required":""},model:{value:(_vm.selectedDevice),callback:function ($$v) {_vm.selectedDevice=$$v},expression:"selectedDevice"}}),_c('t-select',{staticClass:"m-1",attrs:{"options":_vm.styles,"attach":"","placeholder":"Display Style","required":""},model:{value:(_vm.selectedStyle),callback:function ($$v) {_vm.selectedStyle=$$v},expression:"selectedStyle"}}),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex"},[_c('t-button',{staticClass:"m-1 bg-aquila-yellow hover:bg-aquila-yellowLight",on:{"click":_vm.allLiveData}},[_vm._v("Reset filters")])],1),_c('div',{staticClass:"flex flex-grow"},[_c('t-button',{staticClass:"m-1 flex-grow bg-aquila-blueDark text-white hover:bg-aquila-blueLight ",attrs:{"type":"submit"},on:{"click":_vm.filterData}},[_vm._v("Filter")])],1)])],1):_vm._e()])])])]),_c('vue-mapbox-map',{ref:"mapbox",staticClass:" flex-grow ",attrs:{"id":"map-container","access-token":_vm.accessToken,"interactive":true,"mapStyle":_vm.mapStyle,"lng":_vm.longitude,"lat":_vm.latitude,"zoom":_vm.zoom,"pitch":_vm.pitch,"bearing":_vm.bearing},on:{"mapbox-ready":_vm.setMap}}),(!_vm.autoLive)?_c('div',[(_vm.map && !_vm.loading)?_c('vue-mapbox-feature',{attrs:{"map":_vm.map,"uid":'geoJsonData',"layer-type":_vm.selectedStyle === 'LineString'
          ? 'line'
          : _vm.selectedStyle === 'MultiPoint'
          ? 'circle'
          : 'heatmap',"paint":_vm.selectedStyle === 'LineString'
          ? _vm.linePaint
          : _vm.selectedStyle === 'MultiPoint'
          ? _vm.circlePaint
          : _vm.heatmapPaint,"feature":_vm.geoJson.properties != null ? _vm.geoJson : null}}):_vm._e()],1):_vm._e(),(_vm.map)?_c('div',_vm._l((_vm.geoJson.features),function(feature,index){return _c('div',{key:index},[_c('div',[_c('MapMarker',{attrs:{"map":_vm.map,"coordinates":[
            feature.geometry.coordinates[0],
            feature.geometry.coordinates[1] ]}},[_c('template',{slot:"marker"},[_c('div',[_c('div',{staticClass:"text-aquila-blueDark rounded-3xl h-10 w-10 border-aquila-blueDark border-2 content-center ",class:'bg-' + _vm.colourStatus(feature.properties)},[_c('div',{staticClass:"p-1 "},[(feature.properties.type.category === 'dumptruck')?_c('div',{staticClass:"mt-1"},[_c('dumpTruck')],1):_vm._e(),(feature.properties.type.category === 'dozer')?_c('div',{staticClass:"mt-1"},[_c('dozer')],1):_vm._e(),(feature.properties.type.category === 'excavator')?_c('div',[_c('excavator')],1):_vm._e(),(feature.properties.type.category === 'plant')?_c('div',{staticClass:"mt-1"},[_c('plant')],1):_vm._e()])])])]),_c('template',{slot:"popup"},[_c('DetailedPopup',{attrs:{"data":feature.properties,"machineType":feature.properties.type.category}})],1)],2)],1)])}),0):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-xs p-0 pb-1 m-0 leading-none"},[_c('span',{staticClass:"text-base"},[_vm._v("5")]),_vm._v(" mins ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-xs p-0 pb-1 m-0 leading-none"},[_c('span',{staticClass:"text-base"},[_vm._v("15")]),_vm._v(" mins ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-xs p-0 pb-1 m-0 leading-none"},[_c('span',{staticClass:"text-base"},[_vm._v("30")]),_vm._v(" mins ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-xs p-0 pb-1 m-0 leading-none"},[_c('span',{staticClass:"text-base"},[_vm._v("1")]),_vm._v(" hour ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-xs p-0 pb-1 m-0 leading-none"},[_c('span',{staticClass:"text-base"},[_vm._v("12")]),_vm._v(" hours ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-xs p-0 pb-1 m-0 leading-none"},[_c('span',{staticClass:"text-base"},[_vm._v("24")]),_vm._v(" hours ")])}]

export { render, staticRenderFns }